* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

*:before, *:after {
  box-sizing: border-box;
}

html {
  display: flex;
  justify-content: center;
  padding: 1rem;
	height: 100%;
}

body {
  font-family: sans-serif;
  width: 100%;
  max-width: 1800px;
  min-width: 800px;
	height: 100%;
}

.warpper {
	height: 100%;
}

select, button, input {
  padding: .25rem .5rem;
	outline: none;
}

.add-full, .add-full:visited {
	color: #fff;
}

.tab {
  padding: .5rem 1rem;
  background: #888;
  display: inline-block;
  color: #fff;
}

.add-full {
	display: inline-flex;
  padding: .25rem .5rem;
  background: #eb4559;
  text-decoration: none;
}

.add-mobile {
	display: none;
}

.panels {
	height: 96%;
}

.panel {
  display: none;
  background: #fafafa;
  padding: 1rem;
	position: relative;
	height: 100%;
}

.radio {
  display: none;
}

#one:checked ~ .panels #calendar,
#two:checked ~ .panels #shopping {
  display: block;
  text-align: center;
}

#one:checked ~ .tabs #calendar,
#two:checked ~ .tabs #shopping {
  background: #fafafa;
  color: #000;
}

.tools {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
	align-items: center;
}

.calendar-mobile {
	display: none;
}

.calendar-grid {
  display: grid;
  grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
  align-items: start;
  background: white;
  border-top: 1px solid #dadce0;
  border-left: 1px solid #dadce0;
  font-size: .75rem;
  margin-top: 1rem;
}

.calendar-th, .calendar-cell, .calendar-empty {
  border-bottom: 1px solid #dadce0;
  border-right: 1px solid #dadce0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calendar-th {
  padding: .25rem;
}

.calendar-cell, .calendar-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: .15rem;
  height: 100%;
  min-height: 8rem;
}

.calendar-empty {
  background: repeating-linear-gradient(
  45deg,
  #eeeeee,
  #eeeeee 20px,
  #fafafa 20px,
  #fafafa 40px
);
}

.dish-list, .day {
	width: 100%;
}

.recipe-link {
  display: block;
  width: 100%;
  padding: .15rem .25rem;
  margin: .15rem 0;
  color: #212121;
  text-align: left;
  outline: none;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakfast {
  background: #fbc687;
}

.snack {
  background: #aacdbe;
}

.lunch {
  background: #f4f7c5;
}

.dinner {
  background: #ea907a;
}

.shopping-form {
  display: block;
  padding: 1.5rem;
}

.list-copy {
  padding-bottom: 1.5rem;
}

.shopping-list {
  display: block;
  padding: 0 20%;
  text-align: left;
  list-style-type: none;
  line-height: 1.5rem;
}
