@media screen and (max-device-width: 768px) {
  body {
    font-size: 2em;
  }

  .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .tab {
    padding: 1.25rem;
    text-align: center;
  }

  .tools-left {
    width: 100%;
    text-align: center;
  }

  .tools-left select {
  	margin: 1rem;
  }

  .add-full {
  	display: none;
  }

  .add-mobile {
    display: inline-flex;
    width: 8rem;
    height: 8rem;
    justify-content: center;
    align-items: center;
    background: #eb4559;
    color: white;
    text-decoration: none;
    outline: none;
    font-size: 4em;
    border-radius: 50%;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4);
  }

  select, button, input {
    padding: .75rem;
    font-size: 1em;
  }

  .modalDialog > .modalWindow {
    width: 90%;
    min-width: auto;
    max-width: auto;
  }

  .modalWindow h2 {
    text-align: center;
  }

  .dish select {
    width: 33%;
  }

  .dish input {
    width: 64%;
  }

  .ingredients {
    display: grid;
    grid-template-columns: auto 20% 5rem;
    gap: 1rem 2rem;
    padding: 1rem 0;
  }

  .add-ingredient button, .add-link button {
    font-size: 2.5rem;
    padding: .2rem 0 0 .3rem;
  }

  .outside-link {
    font-size: .75em;
    padding: 1rem 0;
  }

  .recipe-notes textarea {
    font-size: 1.75rem;
  }

  .form-buttons {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 3rem;
  }

  .close {
    line-height: 45px;
    width: 45px;
}

  .calendar-grid {
    display: none;
  }

  .calendar-mobile {
  	display: block;
  }

  .days-mobile {
    display: inline-grid;
    grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
    padding: 1rem;
    grid-gap: 1.25rem;
    justify-content: center;
  }

  .day-mobile {
    display: inline-flex;
    width: 5rem;
    height: 5rem;
    justify-content: center;
    align-items: center;
  }

  .dish-list {
    display: none;
    padding: 10px;
  }

  .days-radio {
    display:none;
  }

  #day1:checked ~ .dish-lists #dish-list-1,
  #day2:checked ~ .dish-lists #dish-list-2,
  #day3:checked ~ .dish-lists #dish-list-3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 25rem;
  }

  #day1:checked ~ .days-mobile #label-day1,
  #day2:checked ~ .days-mobile #label-day2,
  #day3:checked ~ .days-mobile #label-day3{
    background: #1976d2;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
  }

  .recipe-link {
    padding: 1.25rem;
    margin: .75rem 0;
  }

  .shopping-form {
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
  }

  .shopping-form input, .shopping-form button {
    margin: .5rem;
  }

  .shopping-form input {
    width: 16rem;
  }

  .shopping-list {
    padding: 0 10%;
    line-height: 3.5rem;
  }

}
