.modalDialog {
  position: fixed;
  height:100vh;
  overflow:auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity:0;
  pointer-events: none;
}

.modalDialog.active {
  opacity:1;
  pointer-events: auto;
}

.modalDialog > .modalWindow {
  width: 70%;
  min-width: 800px;
  max-width: 1200px;
  position: relative;
  margin: 3rem auto;
  padding: 2rem;
  background: #fff;
}

.modalWindow h2 {
  padding: 0 1rem;
}

.close {
  background: #606061;
  color: #FFFFFF;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 24px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.close:hover {
  background: #00d9ff;
}

.dish {
  padding: .75rem 0;
  display: grid;
  grid-template-columns: auto auto 1fr;
}

.modalDialog select, .modalDialog button, .modalDialog input, .modalDialog input[type="date"] {
  padding: 0 1rem;
  box-sizing: content-box;
  min-height: 2rem;
  font-size: 1em;
  margin: 0 .25rem;
  outline: none;
}

.dish input, .dish select {
  font-weight: bold;
}

.ingredients {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  gap: 1rem 2rem;
  padding: 1rem;
}

.ingredients a, .delete-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  padding-left: 1rem;
  text-decoration: none;
  outline: none;
}

.add-ingredient {
  display: grid;
  grid-template-columns: auto 8rem 8rem 5.5rem;
}

.outside-link {
  display: inline-grid;
  grid-template-columns: auto 5.5rem;
  align-items: center;
  padding: 1rem .5rem 1rem 1rem;
}

.add-link {
  display: grid;
  grid-template-columns: auto 5.5rem;
  padding: 1rem;
}

.add-ingredient, .add-link {
  padding: .5rem 0 1.5rem 0;
  border-bottom: solid 1px #c5c5c5;
}

.recipe-notes {
  padding: .5rem .25rem 1.5rem .25rem;
  border-bottom: solid 1px #c5c5c5;
}

.recipe-notes textarea {
  width: 100%;
  padding: 1rem;
}

.form-buttons {
  padding-top: 1rem;
  text-align: right;
}
